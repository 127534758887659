import React from 'react';
import WebsiteDefaultPage from '../components/Page/Base';
import { LIGHT_DARK } from '../constants';
import { Color } from '../styles';

import TermsOfService from '../components/PageSpecific/legal/TermsOfService';

const TermsOfServicePolicy = () => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <TermsOfService />
    </WebsiteDefaultPage>
  );
};

export default TermsOfServicePolicy;
